<template>
    <div>
        <slot
            :news-categories="newsCategories"
            :update-news-categories="updateNewsCategories"
            :news-types="newsTypes"
            :update-news-types="updateNewsTypes"
        ></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    newsCategories: {
        type: Array,
        required: false,
    },
    newsTypes: {
        type: Array,
        required: false,
    },
});

const newsCategories = ref(props.newsCategories);

const updateNewsCategories = (e) => {
    if (newsCategories.value.includes(e.target.value)) {
        newsCategories.value = newsCategories.value.filter((item) => {
            return item !== e.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    newsCategories.value.push(e.target.value);

    // eslint-disable-next-line
    submit();
};

const newsTypes = ref(props.newsTypes);

const updateNewsTypes = (e) => {
    if (newsTypes.value.includes(e.target.value)) {
        newsTypes.value = newsTypes.value.filter((item) => {
            return item !== e.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    newsTypes.value.push(e.target.value);

    // eslint-disable-next-line
    submit();
};

const submit = () => {
    const searchParams = new URLSearchParams();

    if (newsCategories.value.length) {
        searchParams.append('category', newsCategories.value.join(','));
    }

    if (newsTypes.value.length) {
        searchParams.append('type', newsTypes.value.join(','));
    }

    window.location.href = `/news?${searchParams.toString()}`;
};

</script>
