<template>
    <div>
        <slot
            :business-types="businessTypes"
            :update-business-types="updateBusinessTypes"
            :accreditations="accreditations"
            :update-accreditations="updateAccreditations"
            :products="products"
            :update-products="updateProducts"
            :supplies="supplies"
            :update-supplies="updateSupplies"
            :services="services"
            :update-services="updateServices"
            :cities="cities"
            :update-cities="updateCities"
            :states="states"
            :update-states="updateStates"
            :search="search"
            :update-search="updateSearch"
        ></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    businessTypes: {
        type: Array,
        required: false,
    },
    accreditations: {
        type: Array,
        required: false,
    },
    products: {
        type: Array,
        required: false,
    },
    supplies: {
        type: Array,
        required: false,
    },
    services: {
        type: Array,
        required: false,
    },
    cities: {
        type: Array,
        required: false,
    },
    states: {
        type: Array,
        required: false,
    },
    search: {
        type: String,
        required: false,
    },
});

const businessTypes = ref(props.businessTypes);

const updateBusinessTypes = (event) => {
    if (businessTypes.value.includes(event.target.value)) {
        businessTypes.value = businessTypes.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    businessTypes.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const accreditations = ref(props.accreditations);

const updateAccreditations = (event) => {
    if (accreditations.value.includes(event.target.value)) {
        accreditations.value = accreditations.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    accreditations.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const products = ref(props.products);

const updateProducts = (event) => {
    if (products.value.includes(event.target.value)) {
        products.value = products.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    products.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const supplies = ref(props.supplies);

const updateSupplies = (event) => {
    if (supplies.value.includes(event.target.value)) {
        supplies.value = supplies.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    supplies.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const services = ref(props.services);

const updateServices = (event) => {
    if (services.value.includes(event.target.value)) {
        services.value = services.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    services.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};

const cities = ref(props.cities);

const updateCities = (event) => {
    if (cities.value.includes(event.target.value)) {
        cities.value = cities.value.filter((item) => {
            return item !== event.target.value;
        });

        // eslint-disable-next-line
        submit();

        return;
    }

    cities.value.push(event.target.value);

    // eslint-disable-next-line
    submit();
};


const states = ref(props.states);

const updateStates = (event) => {
    if (states.value.includes(event.target.value)) {
        states.value = states.value.filter((item) => {
            return item !== event.target.value;
        });

        submit();

        return;
    }

    states.value.push(event.target.value);

    submit();
};


const search = ref(props.search);

const updateSearch = (event) => {
    search.value = event.target.value;

    submit();
};


const submit = () => {
    const searchParams = new URLSearchParams();

    if (businessTypes.value.length) {
        searchParams.append('type', businessTypes.value.join(','));
    }

    if (accreditations.value.length) {
        searchParams.append('accreditation', accreditations.value.join(','));
    }

    if (products.value.length) {
        searchParams.append('product', products.value.join(','));
    }

    if (supplies.value.length) {
        searchParams.append('supply', supplies.value.join(','));
    }

    if (services.value.length) {
        searchParams.append('service', services.value.join(','));
    }

    if (cities.value.length) {
        searchParams.append('city', cities.value.join(','));
    }

    if (states.value.length) {
        searchParams.append('state', states.value.join(','));
    }

    if (search.value) {
        searchParams.append('search', search.value);
    }

    window.location.href = `/trade-register?${searchParams.toString()}`;
};

</script>
